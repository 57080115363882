<template>
  <a-carousel :autoplay="false" :dots="false">
    <div>
      <a-image :src="'/images/index_' + language + '.jpg'" :preview="false"/>
    </div>
  </a-carousel>
  <section id="about" class="home-about">
    <a-row class="home-about-div">
      <a-col :span="14">
        <a-space direction="vertical" align="start">
          <div class="home-title">{{ $t('about_us') }}</div>
          <a-image src="/images/about.webp" :style="{ width: '100%' }" :preview="false"/>
          <a-image src="/images/f10.webp" :style="{ width: '40px',marginTop:'15px' }" :preview="false"/>
        </a-space>
      </a-col>
      <a-col :span="10">
        <div class="about-title">{{ $t('app_name') }}</div>
        <div class="about-content" v-if="isZhCn">
          <p>几度旅行成立于2014年，专注于酒店行业的客房分销业务，通过高效稳定的API接口及分销网站，搭建了以酒店产品为核心的资源整合与全球分销平台。</p>
          <p>经过多年累积，与全球知名的酒店批发商均有长期稳定的合作，酒店资源覆盖全球大部分城市，可实时预订全球超过50万家酒店。我们的分销合作伙伴包括知名在线旅游平台OTA，同业批发商，旅行社，定制游公司等。</p>
          <p>几度旅行通过连接酒店供应端和渠道需求端，致力于服务和赋能全球各地的旅游从业者，不止为合作伙伴提供高性价比的酒店产品，更提供贴心无忧的售后服务，是客户值得信赖的合作伙伴。</p>
        </div>
        <div class="about-content" v-else>
          <p>Jidu Travel was founded in 2014, which has been focusing on the accommodation distribution in the hotel industry and built a resource integration and global distribution platform with hotel products as the core through efficient and stable API interfaces and distribution websites.</p>
          <p>With years of accumulation, Jidu Travel has established long-term and stable partnerships with many world-famous hotel wholesalers. It has the hotel resources covering most cities in the world and can make real-time hotel reservation on more than 500 thousand hotels. Our clients include well-known OTAs, peer wholesalers, travel agencies, customized travel companies, etc.</p>
          <p v-if="false">By connecting the hotel supply side and the channel demand side, Jidu Travel has been committed to serving and empowering tourism practitioners all over the world, which is a trustworthy partner of customers, not only providing them with cost-effective hotel products, but also providing intimate and reassuring after-sales service.</p>
        </div>
      </a-col>
    </a-row>
  </section>
  <section class="home-hotels">
    <div class="top-hotels">
      <div style="width: 100%;display: flex;justify-content: space-between;">
        <div class="home-title">{{ $t('recommended_hotels') }}</div>
        <div>
          <a-radio-group v-model:value="selectTopCity" button-style="solid" v-for="(city,i) in topHotels" v-bind:key="i">
            <a-radio-button :value="city.city_code">{{ isZhCn ? city.city_name_cn : city.city_name_en }}</a-radio-button>
          </a-radio-group>
        </div>
      </div>
      <template v-for="(city,i) in topHotels" v-bind:key="i">
        <div class="hotels" v-if="city.city_code === selectTopCity">
          <a-row :gutter="[16,16]">
            <template v-for="(hotel, j) in city.list" v-bind:key="j">
              <a-col :span="8">
                <div class="hotel"
                     :style="{ backgroundImage: 'url(\'' + getFullImageUrl(hotel.image_url, false) + '\')'}">
                  <a @click="goDetail(hotel.hotel_id);">
                    <div class="hotel-text">
                      <a-space direction="vertical">
                        <span>
                          <StarFilled class="starColor" v-for="i in parseInt(hotel.hotel_star)" v-bind:key="i"/>
                        </span>
                        <span v-if="!isEmpty(hotel.hotel_name_cn) && isZhCn">{{ hotel.hotel_name_cn }}</span>
                        <span>{{ hotel.hotel_name_en }}</span>
                      </a-space>
                    </div>
                  </a>
                </div>
              </a-col>
            </template>
          </a-row>
        </div>
      </template>
    </div>
  </section>
  <section id="partner" class="home-partner">
    <a-row class="home-partner-div">
      <div class="home-title">{{ $t('partners') }}</div>
      <a-col :span="24">
        <a-carousel arrows :dots="false" :autoplay="true" effect="fade">
          <template #prevArrow>
            <div class="custom-slick-arrow" style="left: -35px; z-index: 1">
              <LeftCircleFilled :style="{ color: '#234BA1'}" />
            </div>
          </template>
          <template #nextArrow>
            <div class="custom-slick-arrow" style="right: -35px">
              <RightCircleFilled :style="{ color: '#234BA1'}" />
            </div>
          </template>
          <div v-for="arr in logos" v-bind:key="arr">
            <a-row :gutter="[5,40]" type="flex" justify="start" align="middle">
              <a-col :span="4" v-for="item in arr" v-bind:key="item">
                <a-card hoverable>
                  <a-image :src="item.src" :alt="item.title" :preview="false" :style="{ backgroundColor:item.bcolor, maxHeight:'60px', height: item.height?item.height:'auto',width: item.width?item.width:'100%' }"/>
                </a-card>
              </a-col>
            </a-row>
          </div>
        </a-carousel>
      </a-col>
    </a-row>
  </section>
</template>

<script>
import { LeftCircleFilled, RightCircleFilled, StarFilled } from '@ant-design/icons-vue';
import {isEmpty,getFullImageUrl} from "@/utils/util";
import {getTopHotels} from "@/services/hotel";

export default {
  name: "Home",
  components: {
    LeftCircleFilled,
    RightCircleFilled,
    StarFilled
  },
  data() {
    return {
      isZhCn: this.$store.getters["setting/getLanguage"] === 'zh_CN',
      language: this.$store.getters["setting/getLanguage"],
      topHotels: [],
      selectTopCity: '',
      logos:[[{
        src: '/images/logo/jalan.svg',
        title: 'Jalan',
      },{
        src: 'https://www.jtbcorp.jp/common_eng/img/logo.png',
        title: 'Jtb'
      },{
        src: 'https://discover9-live.ed-integrations.com//sites/default/files/2022-06/logo_hotelbeds.png',
        title: 'Hotelbeds'
      },{
        src: 'https://www.ntainbound.com/wp/wp-content/themes/nta/src/images/common/logo.svg',
        title: 'Nta'
      },{
        src: '/images/logo/miki.png',
        title: 'Miki'
      },{
        src: '/images/logo/webbeds.svg',
        title: 'Webbeds'
      }],[{
        src: '/images/logo/dotw.jpg',
        title: 'Dotw'
      },{
        src: 'https://www.tboholidays.com/TBOH_NEW/images/new_logo.png',
        title: 'TboHolidays'
      },{
        src: 'https://www.mgbedbank.com/assets/images/MgBedbankLogo.png',
        title: 'mgbedbank',
        bcolor: '#014268'
      },{
        src: 'https://app.sunseries.travel/images/logo/sunseries-logo-h.svg',
        title: 'Sunseries'
      },{
        src: '/images/logo/smyrooms.svg',
        title: 'Smyrooms'
      },{
        src: 'https://www.resteltravel.com/websB2B/cms/cmsNuevoVersiones/rs2/PORTADA_IMG/LOGO.png',
        title: 'Restel'
      },],[
        {
          src: '/images/logo/travco.jpg',
          title: 'Travco',
          width: '150px'
        },{
          src: '/images/logo/rakuten.jpg',
          title: 'Rakuten',
          width: '150px'
        }
      ]],
    }
  },
  beforeMount() {
    this.getTopHotels()
  },
  methods: {
    isEmpty,getFullImageUrl,
    getTopHotels() {
      getTopHotels().then(res => {
        const resData = res.data
        if (resData.Success) {
          this.topHotels = resData.Data
          if(this.topHotels.length > 0)
            this.selectTopCity = this.topHotels[0].city_code
        } else {
          console.log(this.$t('error.' + resData.Code))
        }
      }).catch(error => {
        console.log(error)
      })
    },
    goDetail(hotel_id){
      this.$router.push({
        name: 'hotel_detail', params: { HotelId: hotel_id }
      })
    }
  }
}

</script>

<style scoped>
/*.ant-carousel :deep(.slick-list){
  padding: 5px 0px;
}*/
.ant-carousel :deep(.custom-slick-arrow) {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #000;
  opacity: 0.3;
  z-index: 1;
}
.ant-carousel :deep(.custom-slick-arrow:before) {
  display: none;
}
.ant-carousel :deep(.custom-slick-arrow:hover) {
  opacity: 1;
}

.home-hotels {
  background: #F8F8F8;
}

.top-hotels .ant-radio-group {
  margin-right: 20px;
}

.top-hotels .hotels {

}

.top-hotels .hotel {
  height: 280px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: rgba(0, 0, 0, 0.1);
}

.top-hotels .hotel a {
  display: block;
  width: 100%;
  height: 100%;
}

.top-hotels .hotel .hotel-text {
  position: absolute;
  bottom: 8px;
  background: rgba(0, 0, 0, 0.7);
  padding: 10px 20px;
  color: #FFFFFF;
  width: 96%;
  width: -moz-calc(100% - 16px);
  width: -webkit-calc(100% - 16px);
  width: calc(100% - 16px);
}

.top-hotels .hotel .starColor {
  color: #FFBB00;
  font-size: 20px;
  margin-right: 5px;
}
</style>